<script setup>
import { inject } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useCommentStore } from '@/store/comment';
import { VGtag } from '@/directives'
import { useLayoutStore } from '@/store/layout';
import { accu_product_view } from '@/utils/accuhit'
import Icon from '@/components/Icon'


const $props = defineProps({
  productList: {
    type: Array,
    default: () => []
  }
})
const $emits = defineEmits(['add'])
const ScreenState = inject('ScreenProvider');
const route = useRoute()
const router = useRouter()
const LayoutStore = useLayoutStore()
const CommentStore = useCommentStore()

function addIntoCart(...arg) {
  $emits('add', ...arg)
}

function handleProductClick(product) {
  accu_product_view({
    product_uid: product.product_uid,
    product_name_zh: product.product_name_zh,
    product_price: product.product_price
  }, '產品瀏覽頁')
  router.push(`/product/${product.product_name_en.replace(/\s/g, '-').toLowerCase()}`)
}
</script>

<template>
  <ul class="product-display-list">
    <li
      v-for="product in productList"
      :key="product.product_uid"
      v-gtag:click.view_product="{ location_url: route.fullPath, product_uid: product.product_uid }"
      @click.stop="handleProductClick(product)"
    >
      <router-link
        class="product-image-wrapper"
        :to="`/product/${product.product_name_en.replace(/\s/g, '-').toLowerCase()}`"
      >
        <div class="mask" v-if="ScreenState.mode === 'COMPUTER'">
          查看詳情
        </div>
        <div class="skin-tag" v-if="product.product_function">{{ product.product_function }}</div>
        <img
          :src="product.product_type === 'PRODUCT'
            ? `img/product/${product.product_uid}/${LayoutStore.layoutDict['PRODUCT_AVATAR_NAME']?.layout_content || 'avatar'}.webp`
            : `img/product/${product.product_images[0]}`
          "
          :alt="`${product.product_name_en} ${product.product_name_zh}`" />
      </router-link>
      <div class="product-info">
        <p class="product-subtitle" v-if="product.product_sub_title">{{ product.product_sub_title }}</p>
        <h2>{{ product.product_name_zh }}<br v-if="ScreenState.mode === 'MOBILE'" />
          {{ product.product_size[0] }}
          {{ product.product_size[0] ? 'ml' : '' }}</h2>
        <span class="product-price">
          <p :class="{ 'orginal-price': true}">NT${{ product.product_price.ORIGIN }}</p>
          <p>NT${{ product.product_price.MEMBER }}</p>
        </span>
        <div class="score-info" v-if="CommentStore.filterByProduct[product.product_id]">
          <div class="score">
            <div class="background">
              <icon icon="star-fill" />
              <icon icon="star-fill" />
              <icon icon="star-fill" />
              <icon icon="star-fill" />
              <icon icon="star-fill" />
            </div>
            <div class="fill" :style="{ width: `${CommentStore.filterByProduct[product.product_id].reduce((acc, c) => acc + parseInt(c.comment_stars), 0)/CommentStore.filterByProduct[product.product_id].length/5*100}%` }">
              <icon icon="star-fill" />
              <icon icon="star-fill" />
              <icon icon="star-fill" />
              <icon icon="star-fill" />
              <icon icon="star-fill" />
            </div>
          </div>

          <p>{{ Math.round(CommentStore.filterByProduct[product.product_id].reduce((acc, c) => acc + parseInt(c.comment_stars), 0)/CommentStore.filterByProduct[product.product_id].length*100)/100 }} ({{ CommentStore.filterByProduct[product.product_id].length }})</p>
        </div>
        <button
          :disabled="product.product_is_launched !== '1'"
          v-gtag:click.add_to_cart="{
            currency: 'TWD',
            value: parseInt(product.product_price.MEMBER),
            items: [{
              item_id: product.product_capi_uid,
              item_name: product.product_name_zh,
              item_category: product.product_type.toLowerCase(),
              price: parseInt(product.product_price.MEMBER),
              quantity: 1
            }]
          }" 
          @click.stop="product.product_is_launched === '1' ? addIntoCart(product.product_id) : void 8"
        >{{ product.product_is_launched === '1' ? '加入購物車' : '商品缺貨中' }}</button>
      </div>
    </li>
  </ul>
</template>
