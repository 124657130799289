import { fetchApi } from "@/utils";
import moment from "moment";

// 1 Banner 點擊
export function accu_banner_click(banner, fromPage) {
  console.log("accu_banner_click")
  dos.beaconSend(32, 3, 1, 1,
    banner.banner_name, //Banner 名稱
    banner.category, //Banner 類別
    banner.banner_url, //Banner URL
    fromPage //來源
  );
}

//4 商品瀏覽
export function accu_product_view(product, fromPage) {
  console.log("accu_product_view")
  dos.beaconSend(32, 3, 1, 4,
    'BASIC_LINE', //商品主類別
    product.product_uid, //商品編號
    'CHRIS FARRELL', //品牌名稱
    product.product_name_zh, //商品名稱
    product.product_price.ORIGIN, //原始價格
    product.product_price.MEMBER, //促銷價格
    '', //商品活動
    '', //點擊按鈕名稱
    fromPage, //點擊按鈕位置
  );
}

//6 文章瀏覽
export function accu_article_view(article, fromPage) {
  console.log("accu_article_view")
  dos.beaconSend(32, 3, 1, 6,
    '保養知識', //文章主類別
    '', //文章次類別
    article.article_id, //文章代碼
    article.article_name, //文章名稱
    article.article_create_at.format('YYYY-MM-DD'),// 文章發布日期,
    '',// 文章作者,
    '',// 點擊按鈕名稱,
    fromPage// 點擊按鈕位置
  );
}

export function accu_register(user) {
  const { userId, user_name, user_email, user_phone, user_address } = user
  console.log("accu_register")
  console.log({ userId, user_name, user_email, user_phone, user_address });
  const userPhoneWithZero = String(user_phone[0]) === '0' ? user_phone : `0${user_phone}`
  dos.beaconSend(32, 3, 1, 10, userId, user_name, userPhoneWithZero, user_email, user_address, "", "", "", "", "" );
}

export function accu_login(userId) {
  console.log("accu_login")
  console.log(fpc);
  dos.beaconSend(32, 3, 1, 13, userId, fpc, "www.chris-farrell.com.tw");
}

export function accu_line_bind(userId, userToken) {
  console.log("accu_line_bind")
  console.log(userId, userToken);
  dos.beaconSend(32, 3, 1, 13, userId, userToken, "@chrisfarrell");
  accu_login(userId)
}

// 14 訂單結帳
export function accu_order(userId, order) {
  const now = moment().format('YYYY-MM-DD')
  dos.beaconSend(32, 3, 1, 14,
    userId, // User UID
    order.orders_id, // 訂單編號
    1, // 訂單編號類型 1:訂單編號 2:發票編號
    order.order_amount, // 促銷金額
    '', //原始金額
    order.order_products.length, // 商品數量
    'BASIC_LINE', // 大類別
    '', // 中類別
    '', // 小類別
    order.order_products.map(p => p.uid).join(","), // 商品編號
    order.order_products.map(p => p.product_name_zh).join(","), // 商品名稱
    'CHRIS FARRELL', // 商店編號
    'www.chris-farrell.com.tw', // 購買來源
    now //結帳時間
  );
}

// 15 新增購物車商品
export function accu_add_to_cart(userId, product, count=1, priceType, fromPage) {
  const now = moment().format('YYYY-MM-DD')
  dos.beaconSend(32, 3, 1, 15,
    userId, // User UID
    product.product_price[priceType], // 促銷金額
    product.product_price.ORIGIN, // 原始金額
    count, // 數量
    'BASIC_LINE', // 主類別
    '', // 次類別
    'CHRIS FARRELL', // 品牌名稱
    product.product_uid, //商品編號
    product.product_name_zh, //商品名稱
    fromPage, // 加入購物車來源
    now //加入購物車時間
  );
}

// 16 移除購物車商品
export function accu_remove_from_cart(userId, product, count=1) {
  dos.beaconSend(32, 3, 1, 16,
    userId, // User UID
    product.product_uid, //商品編號
    count, // 數量
    product.product_name_zh, //商品名稱
    'CHRIS FARRELL', // 品牌名稱
  );
}

// 16-2 清空購物車（下單後/登出時）
export function accu_clean_cart(userId, cartList) {
  console.log("accu_clean_cart")
  console.log(userId, cartList);
  cartList.forEach(product => {
    accu_remove_from_cart(userId, product, product.count)
  })
}

export async function accu_crm_bind(userId) { //尚未完成
  const res = await fetchApi('/api/accuhit/', { body: JSON.stringify({
    member_key: userId,
    token: userId,
    token_from: '贏家風采國際_crm',
  })})
  console.log(res);
  return res
}
