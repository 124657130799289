<script setup>
import { onMounted, ref, watch, inject, onBeforeUnmount, computed } from 'vue';
import { useRoute } from 'vue-router';
import { usePostStore } from '../store/post';
import { getElementPositionY, getElementPositionYWithLoop } from '@/utils'
import Icon from '@/components/Icon'
import { pixelEvent } from '../utils/pixel';
import { useLoginStore } from '../store/login';
import { accu_article_view } from '../utils/accuhit'
import { Swiper, SwiperSlide } from 'swiper/vue'
const PostStore = usePostStore()
const route = useRoute()
const ScreenState = inject('ScreenProvider')

const postContentREF = ref()
const navREF = ref()
const sectionHeaders = ref([])
let stageTimeoutInternalInst
const stageTimeout = ref(0)

const postSwiper = ref()
function setSwiper(swiper) {
  postSwiper.value = swiper
  if (ScreenState.mode === 'COMPUTER') postSwiper.value.disable()
}
const swipeNext = (isNext=true) => postSwiper.value[isNext ? 'slideNext' : 'slidePrev']()

const recommendPosts = computed(() => {
  if (PostStore.postList.length) {
    if (PostStore.currentPost.post_recommend) return PostStore.currentPost.post_recommend.map(id => PostStore.postDict[id])
    return PostStore.postList.filter(p => p.category_id !== '9' && p.post_id !== PostStore.currentPost.post_id).slice(0, 3)
  }
  return []
})

watch(route, newValue => {
  PostStore.currentPostTitle = newValue.params.postTitle
  window.scrollTo({ top: 0, behavior: 'smooth' })
})

onMounted(async () => {
  stageTimeoutInternalInst = setInterval(() => stageTimeout.value += 1, 1000);
  if (!PostStore.postList.length) PostStore.queryPost()
  PostStore.currentPostTitle = route.params.postTitle
  window.scrollTo({ top: 0, behavior: 'smooth' })
})
onBeforeUnmount(() => clearInterval(stageTimeoutInternalInst))

const LoginStore = useLoginStore()
watch(stageTimeout, newVal => {
  if (newVal >= 5) {
    console.log(route.fullPath);
    pixelEvent('ViewContent', {
      user: LoginStore.currentUser
    }, {
      event_source_url: `https://www.chris-farrell.com.tw/blog/${route.params.postTitle}`
    })
    clearInterval(stageTimeoutInternalInst)
  }
})

const accu_article_api_has_been = ref(false)
PostStore.$subscribe((mutation, state) => {
  if (state.currentPostTitle && state.postList.length && !accu_article_api_has_been.value) {
    accu_article_api_has_been.value = true
    const currentPost = PostStore.currentPost
    setTimeout(() => {
      accu_article_view({
        article_id: currentPost.post_id,
        article_name: currentPost.post_title,
        article_create_at: currentPost.post_create_at,
      }, route.query.f === 'B' ? '文章瀏覽頁' : '外站連結')
    }, 3000);
  }
})

watch([postContentREF, route], async ([newVal, newRoute]) => {
  if (newVal) {
    //確認所有圖片都已經 Loading 載入完畢
    const promises = Array.from(newVal.getElementsByTagName('img')).map(e => new Promise((res) => {
      if (e.complete) res()
      e.onload = () => res()
    }))
    await Promise.all(promises)

    //計入所有章首Ｙ軸位置並 Render Nav
    sectionHeaders.value = []
    const sectionHeaderElements = newVal.getElementsByClassName('chapter-header')
    console.log(sectionHeaderElements);
    for (let el of sectionHeaderElements) {
      let positionY = getElementPositionYWithLoop(el)
      console.log(positionY);
      const name = Array.from(el.childNodes).filter(node => node.className === 'section-header')[0].id
      sectionHeaders.value.push({ name, yStart: positionY, yEnd: positionY + el.scrollHeight })
    }
  }
})


const windowHeight = window.innerHeight
const scrollTo = (yStart, removeNavHeight=true) => {
  const navHeight = removeNavHeight ? navREF.value.offsetHeight : 0
  window.scrollTo({ top:ScreenState.mode === "MOBILE" ? yStart - 52 + navHeight  : yStart - 112 + navHeight, behavior: 'smooth' })
}

const titleRender = title => {
  return title.replace(/[\:：]/g, '：<br>')
}
</script>

<template>
  <div class="view-post">
    <article v-if="PostStore.postList.length && PostStore.currentPostTitle">
      <header>
        <div class="mask">
          <h1 v-html="titleRender(PostStore.currentPost.post_title)"/>
          <span class="line" />
          <div class="desc" v-html="PostStore.currentPost.post_summary.replace(/\n/g, '<br>').replace(/\s/g, '&nbsp')"/>
          <button @click="scrollTo(windowHeight, false)">
            <p>More</p>
            <span>
              <icon icon="arrow-down-short" />
            </span>
          </button>
        </div>
        <!-- <img
          :src="`../${PostStore.currentPost.post_bannerPath}`"
          :alt="PostStore.currentPost.post_banner"
        /> -->
      </header>

      <nav ref="navREF" v-if="sectionHeaders.length">
        <h2>Table of Contents</h2>
        <ul>
          <li
            v-for="section in sectionHeaders"
            :key="section.name"
            @click="scrollTo(section.yStart)"
          >{{ section.name }}</li>
        </ul>
      </nav>

      <section class="content-wrapper">
        <main>
          <div class="content" ref="postContentREF" v-html="PostStore.currentPost.post_content"></div>
        </main>
        <footer>
          <h2>推薦閱讀</h2>
          <h4>“所有文章皆參考論文撰寫而成，絕非憑空杜撰的草食文章，只傳達有科學背書的皮膚知識”</h4>

          <div class="swiper-out-wrapper">
            <template v-if="ScreenState.mode === 'MOBILE'">
              <button class="m-control left" @click="swipeNext(false)"><icon icon="chevron-left" /></button>
              <button class="m-control right" @click="swipeNext(true)"><icon icon="chevron-right" /></button>
            </template>
            <Swiper
              @swiper="swiper => setSwiper(swiper)"
              :slides-per-view="ScreenState.mode === 'COMPUTER' ? 3 : 1"
              :space-between="ScreenState.mode === 'COMPUTER' ? 24 : 48"
              :loop="true"
            >
              <swiper-slide v-for="post in recommendPosts" :key="post.post_id">
                <router-link :to="`/blog/${post.post_title_en}`">
                  <div class="image-container">
                    <img :src="post.post_bannerPath" :alt="post.post_banner">
                  </div>
                  <div class="content">
                    <h2>{{ post.post_title }}</h2>
                    <span v-html="post.post_summary.replace(/\n/g, '<br>') "></span>
                  </div>
                </router-link>
              </swiper-slide>
            </swiper>
          </div>
        </footer>
      </section>
    </article>
  </div>
</template>
