<script setup>
import ProductExploreBabnner from '@/assets/img/productExploreBabnner.webp'
import ProductKV2 from '@/assets/img/ProductKV2.webp'
import ProductKV3 from '@/assets/img/v3/ProductKV4.webp'
import { ref, computed, watch, inject, onMounted, onBeforeUnmount } from '@vue/runtime-core';
import SwiperCore, { Controller, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue'
import { useIngredientStore } from '@/store/ingredient';
import { useProductStore } from '@/store/product';
import { useLoginStore } from '@/store/login'
import { usePaymentStore } from '@/store/payment'
import { useRouter, useRoute } from 'vue-router';
import { VGtag } from '@/directives'
import Icon from '@/components/Icon'
import { useLayoutStore } from '@/store/layout';
import { useCommentStore } from '@/store/comment'
import { gaEvent } from '@/utils/event'
import { useEventStore } from '@/store/event';
import ProductList from './components/ProductList'
import { accu_add_to_cart, accu_banner_click } from '@/utils/accuhit'

SwiperCore.use([Pagination])
const ScreenState = inject('ScreenProvider');
const router = useRouter()
const route = useRoute()
const ProductStore = useProductStore()
const IngredientStore = useIngredientStore()
const EventStore = useEventStore()
const LayoutStore = useLayoutStore()
const CommentStore = useCommentStore()
const SKIN_TAG_DICT = {
  dry: { tag: '乾', color: '#B58B71' },
  oil: { tag: '油', color: '#E29A27' },
  sensitive: { tag: '敏', color: '#C94848' },
  combination: { tag: '混', color: '#C99748' },
}

onMounted(async () => {
  window.scrollTo(0,0)
  if (!ProductStore.productList.length) ProductStore.queryProduct()
  if (!CommentStore.commentList.length) CommentStore.queryComment()
  if (!EventStore.eventList.length) EventStore.queryEventList()
  gaEvent('view_item_list', {
    item_list_id: 'BASE_LINE',
    item_list_name: 'CF基礎保養系列',
    items: ProductStore.productList.filter(c => c.product_is_launched).map(e => ({
      item_id: e.product_capi_uid,
      item_name: e.product_name_zh,
      item_category: e.product_type.toLowerCase(),
      price: e.product_price.MEMBER,
      quantity: 1
    }))
  })
  carouselFunc()
})

const $message = inject('$message')
const LoginStore = useLoginStore()
const PaymentStore = usePaymentStore()
const addIntoCart = product_id => {
  const productIndex = PaymentStore.cartItems.map(e => e.product_id).indexOf(product_id)
  if (productIndex === -1 || (PaymentStore.cartItems[productIndex] && PaymentStore.cartItems[productIndex].product_price !== 'MEMBER')) {
    PaymentStore.addProductIntoCart({
      product_id: product_id,
      product_quantity: 1,
      product_price: 'MEMBER'
    })
  }
  else PaymentStore.editProductQuantity(product_id, 1)

  //ACCUHIT EVENT @ADD TO CART(如果有登入)
  console.log('accuhit');
  if (LoginStore.currentUser) {
    console.log('accuhit add to cart');
    accu_add_to_cart(
      LoginStore.currentUser.user_uid || LoginStore.currentUser.user_line_id,
      ProductStore.productDict[product_id],
      1, //新增商品數量 
      'MEMBER',
      '商品總瀏覽頁'
    )
  }
  $message.success('已加入購物車')
}

const carouselSwiper = ref(null)
const carouselPureImages = []
const carouselMobilePureImages = []
function setSwiper(swiper) {
  carouselSwiper.value = swiper
  swiper.slideTo(1)
}
function swipeNext(isNext=true) {
  carouselSwiper.value[isNext ? 'slideNext' : 'slidePrev']()
}

const currentTimeout = ref()
const carouselSensor = ref()
watch(carouselSensor, changeCarouselPage)
async function carouselFunc() {
  currentTimeout.value = setTimeout(() => {
    carouselSensor.value = !carouselSensor.value
  }, 5000);
}
function changeCarouselPage(isAuto=true, isNext=true) {
  if (!isAuto) carouselSwiper.value[isNext ? 'slideNext' : 'slidePrev']()
  else swipeNext(true)
  clearTimeout(currentTimeout.value)
  carouselFunc()
}
onBeforeUnmount(() => clearTimeout(currentTimeout.value))

const productsFilterByFeature = computed(() => {
  if (ProductStore.productList.length) {
    if (route.query.feature) {
      return ProductStore.productAndSet.PRODUCT.filter(e => e.product_feature.includes(route.query.feature))
    }
    return ProductStore.productAndSet.PRODUCT
  }
  return []
})

async function handleBannerClick(event) {
  await accu_banner_click({
    banner_name: event.event_name,
    category: '優惠活動',
    banner_url: `/event/${event.event_url}`
  }, '產品瀏覽頁')
}

const FeatureDict = {
  'wrinkles-fine-lines': '皺紋細紋',
  'dullness-uneven-texture': '暗沉蠟黃',
  'dryness': '皮膚乾燥',
  'hand-dry': '手部乾燥',
  'acne-blemishes': '痘痘面皰',
  'enlarged-pores': '毛孔粗大',
  'all-skin-types': '各式皮膚問題'
}
</script>

<template>
  <div class="view-product-explore">
    <header>
      <button class="control left" @click="swipeNext(false)">
        <Icon icon="chevron-left" />
      </button>
      <button class="control right" @click="swipeNext(true)">
        <Icon icon="chevron-right" />
      </button>
      <swiper
        @swiper="swiper => setSwiper(swiper)"
        :loop="true"
        :pagination="{ clickable: true }"
        :allowTouchMove="ScreenState.mode === 'MOBILE'"
      >
        <swiper-slide  
          v-for="event in (EventStore.activeEvents.filter(e => e.event_state === 'active') || [])" 
          :key="`carousel_pure_img_${event.event_id}`"
        >
          <router-link :to="`/event/${event.event_url}`" @click="() => handleBannerClick(event)" >
            <img :src="`/img${event.event_banner}`"  :alt="event.event_name || 'Chris Farrell 克利斯瑞'">
          </router-link>
        </swiper-slide>
      </swiper>
    </header>

    <section v-if="ProductStore.productAndSet.PRODUCT.length">
      <template v-if="route.query.feature">
        <header>
          <p>功能：{{ FeatureDict[route.query.feature] }}</p>
          <button @click="router.push('/product')">顯示所有商品</button>
        </header>
        <product-list :productList="productsFilterByFeature" @add="addIntoCart" />
      </template>
      <template v-else>
        <header v-if="ProductStore.hotSaleProducts.length">熱銷商品</header>
        <product-list v-if="ProductStore.hotSaleProducts.length" :productList="ProductStore.hotSaleProducts" @add="addIntoCart" />
        <header>所有商品</header>
        <product-list :productList="productsFilterByFeature" @add="addIntoCart" />
      </template>
    </section>

    <section class="garantee">
      <img :src="ProductKV3" alt="Chris Farrell 克利斯瑞">
      <div class="content-wrapper">
        <h2>7天鑑賞期，不滿意退費</h2>
        <span>
          您在訂購 Chris Farrell 的產品時，會額外附上該產品的試用品，您可以先透過試用品確認產品適合您的皮膚且不會造成過敏反應，如有任何不滿，7天內可全額退費。<br>(*詳細退貨規則以官網公布的退款條款為準)
        </span>
      </div>
    </section>

    <section class="set-items-wrapper" v-if="ProductStore.productAndSet.SET.length">
      <header>保養組合</header>
      <product-list :productList="ProductStore.productAndSet.SET" @add="addIntoCart" />
    </section>

    <section class="origin-member">
      <img :src="ProductKV2" alt="Chris Farrell 克利斯瑞Chris Farrell 克利斯瑞">
      <div class="content-wrapper">
        <h2>前1000名消費滿3000元會員<br>即升級為創始會員身份</h2>
        <ul>
          <li>成長型推薦碼購物金回饋</li>
          <li>消費 1.5% 購物金回饋</li>
          <li>享有專屬生日禮，由特約美容師針對膚況提供專屬生日組合</li>
          <li>更多專屬優惠</li>
        </ul>
        <button @click="router.push('/vip-rights')">深入瞭解</button>
      </div>
    </section>

    <section class="we-insist">
        <h1 class="section-title">我們對產品的堅持</h1>
        <span>
          克莉斯瑞的產品配方在這50多年來隨著科技研究的創新不斷進展，根據最新的皮膚分析檢測指數資料來進行配方的調整。如果我們無法獲得產品所需要的成分，在經實驗找到更好的替代成分之前，我們會將產線停產，直到產品成分恢復供應。
        </span>
        <p>在採購產品原料後我們還會進行以下流程</p>
        <ul class="flow">
          <li><img :src="require('@/assets/img/Inist_Flow_1.svg')" alt="Chris Farrell 檢驗流程"><p>化驗</p></li>
          <icon icon="caret-right-fill" />
          <li><img :src="require('@/assets/img/Inist_Flow_2.svg')" alt="Chris Farrell 檢驗流程"><p>微生物檢驗</p></li>
          <icon icon="caret-right-fill" />
          <li><img :src="require('@/assets/img/Inist_Flow_3.svg')" alt="Chris Farrell 檢驗流程"><p>毒物學評鑑</p></li>
          <icon icon="caret-right-fill" />
          <li><img :src="require('@/assets/img/Inist_Flow_4.svg')" alt="Chris Farrell 檢驗流程"><p>製成成品</p></li>
          <icon icon="caret-right-fill" />
          <li><img :src="require('@/assets/img/Inist_Flow_5.svg')" alt="Chris Farrell 檢驗流程"><p>再次毒物檢驗</p></li>
        </ul>

        <span>
          所有 Chris Farrell 的保養品皆在德國原廠完成製造並空運來台，製造的流程皆遵循最嚴格的WHO規範，直到成品包裝完成前，所有製造過程皆受到嚴密監控與把關。
        </span>
      </section>
  </div>
</template>
