<script setup>
import { usePaymentStore } from "../../store/payment";
import { useProductStore } from "../../store/product";
import Icon from '@/components/Icon'
import { useRouter } from "vue-router";
import { inject, defineExpose, onMounted, ref } from "vue";
import { useLoginStore } from "../../store/login";
import { VGtag } from '@/directives'
import { pixelEvent, genEventUid } from "../../utils/pixel";
import { useEventStore } from "../../store/event";
import { getCookieByKey } from '../../utils'
import ReminderBackground from '@/assets/img/productExploreBabnner.webp'
import { useCouponStore } from "../../store/coupon";
import { accu_add_to_cart } from '@/utils/accuhit'

const Props = defineProps({ nextStep: Function })
const PaymentUseCoupon = inject('PaymentUseCoupon')
const ScreenProvider = inject('ScreenProvider')
const router = useRouter()
const ScreenState = inject('ScreenProvider')
const EventStore = useEventStore()
const PaymentStore = usePaymentStore()
const ProductStore = useProductStore()
const LoginStore = useLoginStore()
const $message = inject('$message')

const reminderTimeout = ref()
const showMemberLoginReminder = ref(false)
onMounted(() => {
  if (!EventStore.eventList.length) EventStore.queryEventList()
  const PaymentTimeoutStart = getCookieByKey('cf:payment_start')
  const paymentTimeout = 420000 - (new Date().getTime() - PaymentTimeoutStart)
  // if (!LoginStore.currentUser && paymentTimeout < 0 ) {
  //   reminderTimeout.value = setTimeout(() => showMemberLoginReminder.value = true, 1000)
  // }
  pixelEvent('ViewContent', {
    user: LoginStore.currentUser,
    custom_data: {
      content_type: 'product',
      content_category: 'Payment',
      content_ids: PaymentStore.cartItems.map(e => ProductStore.productDict[e.product_id].product_capi_uid),
      contents: JSON.stringify(PaymentStore.cartItems.map(e => ({
        id: ProductStore.productDict[e.product_id].product_capi_uid,
        quantity: e.product_quantity,
        item_price: ProductStore.productDict[e.product_id].product_price[e.product_price]
      }))),
      currency: 'TWD',
      value: PaymentStore.sumOfCartPrice + PaymentStore.freeShippingCost
    }
  }, {
    event_source_url: `/payment/cart`,
  }, true)
})

LoginStore.$subscribe(async (mutation, state) => {
  if (state.currentUser) {
    clearTimeout(reminderTimeout.value)
    showMemberLoginReminder.value = false
  }
})

function closeReminder() {
  showMemberLoginReminder.value = false
}

function getItemsContentOfSet(product_id) {
  if (ProductStore.productList.length && EventStore.eventList.length) {
    // const giveawayInEventRelated = [...(EventStore.productsInEvent[ProductStore.productDict[product_id]] || []), ...EventStore.activeEvents.filter(e => e.event_linked_product_id[0] === 'ALL')].reduce((acc, event) => {
    //   if (event.coupon_product) {
    //     const productIds = event.coupon_product.split(',')
    //     return [...acc, ...productIds.map(id => ({ event_id: event.event_id, ...ProductStore.productDict[id] }))]
    //   }
    //   return acc
    // }, [])
    // const uniqueGiveawayInEventRelated = giveawayInEventRelated.filter((g, i, arr) => arr.findIndex(g2 => g2.product_id === g.product_id) === i)
    // const samples = ProductStore.productDict[product_id].product_set_items.map(id => ProductStore.productDict[id].product_sample).filter(s => s).map(s => ProductStore.productUidDict[s])
    // const itemsContent = [...uniqueGiveawayInEventRelated.map(e => `${e.product_name_zh}${e.product_size[0] ? ' '+e.product_size[0]+'ml' : ''}`), ...samples.map(s => `${s.product_name_zh}${s.product_size[0] ? ' '+s.product_size[0]+'ml' : ''}`)].join(', ')
    // return `內含：${itemsContent}`
    return `內含${ProductStore.productDict[product_id].product_set_items.map(id => `${ProductStore.productDict[id].product_name_zh}${ProductStore.productDict[id].product_size[0] ? ' '+ProductStore.productDict[id].product_size[0]+'ml' : ''}`).join(', ')}`
  }

  return ''
}

function addAdditionalProduct(product) {
  if (PaymentStore.cartItems.length) {
    PaymentStore.addProductIntoCart({
      product_id: product.product_id,
      product_quantity: 1,
      product_price: 'ADDITIONAL',
    })

    //ACCUHIT EVENT @ADD TO CART(如果有登入)
    console.log('accuhit');
    if (LoginStore.currentUser) {
      console.log('accuhit add to cart');
      accu_add_to_cart(
        LoginStore.currentUser.user_uid || LoginStore.currentUser.user_line_id,
        product,
        1, //新增商品數量 
        'ADDITIONAL',
        '購物車頁面'
      )
    }
  }
  else $message.warning('請先加入主要產品')
}

function cartIncludesAdditionalProduct(product_id) {
  return PaymentStore.cartItems.filter(e => e.product_price === 'ADDITIONAL').map(e => e.product_id).includes(product_id)
}

function toProduct(item) {
  const product = ProductStore.productDict[item.product_id]
  if (product.product_is_launched !== '0') router.push(`/product/${product.product_name_en.replace(/\s/g, '-').toLowerCase()}`)
}

const toShipping = () => {
  PaymentStore.saveInfoToSession('CART')
  if (PaymentStore.cartItems.length) {
    if (ScreenProvider.mode === "COMPUTER") Props.nextStep()
    return true
  }
  else {
    $message.warning('購物車中沒有產品，請先加入你想購買的商品喔～')
    return false
  }
}

function getSample(product) {
  if (ProductStore.productList.length === 0 || ProductStore.productDict[product.product_id].product_uid === '2716' ) {
    return {}
  }
  return ProductStore.productUidDict[ProductStore.productDict[product.product_id].product_sample] || {}
}

const CouponStore = useCouponStore()
async function handleReChoose(item) {
  const { coupon } = await CouponStore.getCouponeWithUid(item.coupon_uid, 'SYSTEM')
  PaymentStore.removeCoupon(item.coupon_uid)
  PaymentUseCoupon(coupon)
}

defineExpose({ handleNext: toShipping })
</script>

<template>
  <div class="view-cart">
    <div class="bill">
      <header>
        <h2>我的購物車</h2>
      </header>
      <table v-if="ScreenState.mode === 'COMPUTER'">
        <tr class="table-head">
          <td>品項</td>
          <td>產品資訊</td>
          <td v-if="ScreenState.mode ==='COMPUTER'">單價</td>
          <td>數量</td>
          <td>總金額</td>
          <td></td>
        </tr>

        <tr class="item" v-for="item in PaymentStore.cartItems" :key="item.product_id">
          <td>
            <div>
              <img
                v-gtag:click.select_item="{
                  product_uid: ProductStore.productDict[item.product_id].product_uid,
                  number_of_products: item.product_quantity
                }"
                :src="ProductStore.productDict[item.product_id]?.product_type === 'SET'
                  ? `/img/product/${ProductStore.productDict[item.product_id].product_images[0]}`
                  : `/img/product/${ProductStore.productDict[item.product_id].product_uid}/avatar.webp`
                "
                @click="toProduct(item)"
              />
            </div>
          </td>
          <td
            v-gtag:click.select_item="{
              product_uid: ProductStore.productDict[item.product_id].product_uid,
              number_of_products: item.product_quantity
            }"
            @click="toProduct(item)"
          >
            <h3>{{ ProductStore.productDict[item.product_id].product_name_zh }} {{ ProductStore.productDict[item.product_id].product_size.length ? ProductStore.productDict[item.product_id].product_size[0] + 'ml' : '' }}</h3>
            <h4>{{ ProductStore.productDict[item.product_id].product_name_en }} {{ ProductStore.productDict[item.product_id].product_size.length ? ProductStore.productDict[item.product_id].product_size[0] + 'ml' : '' }}</h4>
            <span class="product-price-type" v-if="item.product_price === 'ADDITIONAL'">加價購</span>
            <span class="set-desc" v-if="ProductStore.productDict[item.product_id].product_type==='SET'">
              {{ getItemsContentOfSet(item.product_id) }}
            </span>
          </td>
          <td v-if="ScreenState.mode ==='COMPUTER'">
            <p>${{ ProductStore.productDict[item.product_id].product_price[item.product_price] }}</p>
          </td>
          <td class="product-quantity">
            <div>
              <button v-if="item.product_price !== 'ADDITIONAL'" @click="PaymentStore.editProductQuantity(item.product_id, -1)">
                <icon icon="dash"></icon>
              </button>
              <p>{{ item.product_quantity }}</p>
              <button v-if="item.product_price !== 'ADDITIONAL'" @click="PaymentStore.editProductQuantity(item.product_id, 1)">
                <icon icon="plus"></icon>
              </button>
            </div>
          </td>
          <td>
            <p>
              ${{
                ProductStore.productDict[item.product_id].product_price[item.product_price] * item.product_quantity
              }}
            </p>
          </td>
          <td class="delete">
            <icon
              icon="x-square-fill"
              @click="item.product_price !== 'ADDITIONAL'
                ? PaymentStore.removeProductFromCart(item.product_id)
                : PaymentStore.removeAdditionalProduct(item.product_id) 
              "
            ></icon>
          </td>
        </tr>
        <template v-if="PaymentStore.couponItemList.length">
          <tr class="item giveaway" v-for="item in PaymentStore.couponItemList" :key="`cart-table-coupon-item-${item.product_id}`">
            <td>
              <div>
                <img :src="`/img/product/${ProductStore.productDict[item.product_id].product_uid}/avatar.webp`"/>
              </div>
            </td>
            <td>
              <h4>
                {{ ProductStore.productDict[item.product_id].product_name_zh }} {{ ProductStore.productDict[item.product_id].product_size.length ? ProductStore.productDict[item.product_id].product_size[0] + 'ml' : '' }}
                <button
                  v-if="item.coupon_product.length > parseInt(item.coupon_max_product_chosen)"
                  @click="handleReChoose(item)"
                >重選贈品
                </button>
              </h4>
              <span class="product-price-type">{{ ScreenState.mode ==='COMPUTER' ? '贈品：' : '' }}{{ item.coupon_name }}</span>
              <span class="giveaway-desc">{{ item.coupon_desc }}</span>
            </td>
            <td  v-if="ScreenState.mode ==='COMPUTER'">贈</td>
            <td>{{ item.product_quantity }}</td>
            <td>贈</td>
            <td></td>
          </tr>
        </template>
        <template v-if="PaymentStore.cartItems.some(i => ProductStore.productDict[i.product_id].product_sample)">
          <tr
            class="item giveaway sample"
            v-for="item in PaymentStore.cartItems.filter(i => ProductStore.productDict[i.product_id].product_sample)"
            :key="`cart-table-sample-items-${item.product_id}`"
          >
            <td>
              <div>
                <img :src="`/img/product/${getSample(item).product_uid}/avatar.webp`"/>
              </div>
            </td>
            <td>
              <h4>{{ getSample(item).product_name_zh }} {{ getSample(item).product_size[0] }}ml</h4>
              <span class="product-price-type">試用品</span>
            </td>
            <td  v-if="ScreenState.mode ==='COMPUTER'">贈</td>
            <td>{{ item.product_quantity }}</td>
            <td>贈</td>
            <td></td>
          </tr>
        </template>
      </table>
      <section v-else class="cart-items-mobile">
        <ul>
          <li v-for="item in PaymentStore.cartItems" :key="`mobile-cart-${item.product_id}`">
            <div class="info">
              <div class="delete-item" @click="PaymentStore.removeProductFromCart(item.product_id)"><icon icon="x-lg"/></div>
              <div class="image">
                <img
                  v-gtag:click.select_item="{
                    product_uid: ProductStore.productDict[item.product_id].product_uid,
                    number_of_products: item.product_quantity
                  }"
                  :src="ProductStore.productDict[item.product_id].product_type === 'SET'
                    ? `/img/product/${ProductStore.productDict[item.product_id].product_images[0]}`
                    : `/img/product/${ProductStore.productDict[item.product_id].product_uid}/avatar.webp`
                  "
                  @click="toProduct(item)"
                />
              </div>
              <div class="content">
                <h3>{{ ProductStore.productDict[item.product_id].product_name_zh }} {{ ProductStore.productDict[item.product_id].product_size.length ? ProductStore.productDict[item.product_id].product_size[0]+'ml' : '' }}</h3>
                <div>
                  <h4>{{ ProductStore.productDict[item.product_id].product_name_en.toUpperCase() }} {{ ProductStore.productDict[item.product_id].product_size.length ? ProductStore.productDict[item.product_id].product_size[0]+'ml' : '' }}</h4>
                  <span class="coupon-tag" v-if="item.product_price === 'ADDITIONAL'">加價購</span>
                </div>
                <p>${{ ProductStore.productDict[item.product_id].product_price[item.product_price] }}</p>
                <span class="set-desc" v-if="ProductStore.productDict[item.product_id].product_type==='SET'">
                  {{ getItemsContentOfSet(item.product_id) }}
                </span>
              </div>
            </div>
            <div class="product-quantity">
              <div class="counter" v-if="item.product_price !== 'ADDITIONAL'">
                <button @click="PaymentStore.editProductQuantity(item.product_id, -1)">
                  <icon icon="dash"></icon>
                </button>
                <p>{{ item.product_quantity }}</p>
                <button @click="PaymentStore.editProductQuantity(item.product_id, 1)">
                  <icon icon="plus"></icon>
                </button>
              </div>
              <p v-else class="quantity">&#x00D7; {{ item.product_quantity }}</p>
              <p class="price">
                <span>共</span>
                ${{ ProductStore.productDict[item.product_id].product_price[item.product_price] * item.product_quantity }}
              </p>
            </div>
          </li>
          <template v-if="PaymentStore.couponItemList.length">
            <li class="giveaway" v-for="item in PaymentStore.couponItemList" :key="`cart-table-coupon-item-${item.product_id}`">
              <div class="info">
                <div class="image">
                  <img
                    v-gtag:click.select_item="{
                      product_uid: ProductStore.productDict[item.product_id].product_uid,
                      number_of_products: item.product_quantity
                    }"
                    :src="ProductStore.productDict[item.product_id].product_type === 'SET'
                      ? `/img/product/${ProductStore.productDict[item.product_id].product_images[0]}`
                      : `/img/product/${ProductStore.productDict[item.product_id].product_uid}/avatar.webp`
                    "
                    @click="toProduct(item)"
                  />
                </div>
                <div class="content">
                  <h3>
                    {{ ProductStore.productDict[item.product_id].product_name_zh }} {{ ProductStore.productDict[item.product_id].product_size.length ? ProductStore.productDict[item.product_id].product_size[0]+'ml' : '' }}
                  </h3>
                  <h4>{{ ProductStore.productDict[item.product_id].product_name_en.toUpperCase() }} {{ ProductStore.productDict[item.product_id].product_size.length ? ProductStore.productDict[item.product_id].product_size[0]+'ml' : '' }}</h4>
                  <button
                    v-if="item.coupon_product.length > parseInt(item.coupon_max_product_chosen)"
                    @click="handleReChoose(item)"
                  >重選贈品
                  </button>
                  <p class="price"></p>
                  <span class="coupon-tag">{{ item.coupon_name }}</span>
                  <span class="giveaway-desc">{{ item.coupon_desc }}</span>
                </div>
              </div>
              <div class="product-quantity">
                <p class="quantity">&#x00D7; {{ item.product_quantity }}</p>
                <p class="price">
                  贈品
                </p>
              </div>
            </li>
          </template>
          <template v-if="PaymentStore.cartItems.some(i => ProductStore.productDict[i.product_id].product_sample)">
            <li class="giveaway" v-for="item in PaymentStore.cartItems.filter(i => ProductStore.productDict[i.product_id].product_sample)" :key="`cart-table-sample-item-${item.product_id}`">
              <div class="info">
                <div class="image">
                  <img
                    :src="`/img/product/${getSample(item).product_uid}/avatar.webp`"
                    @click="toProduct(item)"
                  />
                </div>
                <div class="content">
                  <h3>{{ getSample(item).product_name_zh }} {{ getSample(item).product_size[0] }}ml</h3>
                  <h4>{{ getSample(item).product_name_en.toUpperCase() }} {{ getSample(item).product_size[0] }}ml</h4>
                  <p class="price"></p>
                  <span class="coupon-tag">試用品</span>
                </div>
              </div>
              <div class="product-quantity">
                <p class="quantity">&#x00D7; {{ item.product_quantity }}</p>
                <p class="price">贈品</p>
              </div>
            </li>
          </template>
        </ul>
      </section>
      <div class="empty" v-if="!PaymentStore.cartItems.length">目前購物車是空的喔～</div>
      <div class="freeship">
        <a href="https://lin.ee/EssQxJt" target="_blank">點擊加入LINE好友 送你$200元首購券</a> |
        <p v-if="PaymentStore.amount >= PaymentStore.freeShippingThreshold">單筆訂單總額滿 NT$1,000 即可享免運費</p>
        <span
          v-else
        >還差 ${{ PaymentStore.freeShippingThreshold - PaymentStore.sumOfCartPrice }} 即可享免運費優惠！</span>
      </div>
      <!-- <div class="giveaway-list" v-if="PaymentStore.couponItemList.length">
        <h3 class="giveaway-header">贈品</h3>
        <ul>
          <li v-for="item in PaymentStore.couponItemList" :key="`cart-coupon-item-${item.product_id}`">
            <div class="img-wrapper">
              <img
                :src="`/img/product/${ProductStore.productDict[item.product_id].product_uid}/avatar.webp`"
                alt
              />
            </div>
            <div>
              <h4>{{ item.product_name_en }}</h4>
              <h4>{{ item.product_name_zh }}</h4>
              <span>{{ item.coupon_name }}</span>
            </div>
          </li>
        </ul>
      </div> -->
      <div class="giveaway-list additional-list" v-if="ProductStore.additionalProductList.length">
        <h3 class="additional-header">加價購專區</h3>
        <ul>
          <li v-for="product in ProductStore.additionalProductList" :key="`cart-additional-item-${product.product_id}`">
            <div class="img-wrapper">
              <img
                :src="`/img/product/${ProductStore.productDict[product.product_id].product_uid}/avatar.webp`"
              />
            </div>
            <div>
              <h5>{{ product.product_sub_title }}</h5>
              <h4>{{ product.product_name_zh }}</h4>
              <span>加購價 ${{ product.product_price.ADDITIONAL }}</span>
              <p class="origin-price"> 原價 ${{ product.product_price.ORIGIN }}</p>
              <p class="diff">(現省${{ product.product_price.ORIGIN - product.product_price.ADDITIONAL }})</p>
              <button
                :class="cartIncludesAdditionalProduct(product.product_id) ? 'added' : '' "
                @click="addAdditionalProduct(product)" :disabled="cartIncludesAdditionalProduct(product.product_id)"
              >
                {{ cartIncludesAdditionalProduct(product.product_id) ? '已加購' : '加入購物車' }}
              </button>
            </div>
          </li>
        </ul>
      </div>
      <footer>
        <button class="backToChoose" @click="() => router.push('/product')">繼續選購商品</button>
        <button class="next" @click="toShipping">下一步</button>
      </footer>
    </div>

    <div class="line-remider" v-if="showMemberLoginReminder && ScreenState.mode === 'COMPUTER'">
      <div class="mask">
        <div class="content" :style="`background-image: url(${ReminderBackground})`">
          <div class="wrapper">
            <span @click="closeReminder"><icon icon="x-lg"/></span>
            <h3>加入克莉斯瑞 Line@ 好友</h3>
            <p>送$200首購券</p>
            <a href="https://lin.ee/EssQxJt" target="_blank"><button>馬上加入</button></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
