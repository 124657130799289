function findValueOfNestedObject(keyString, obj) {
  if (!obj) return null
  if (keyString.includes('.')) {
    const [key, ...rest] = keyString.split('.')
    let newObj = obj[key]
    if (/\[.*\]/.test(key)) {
      const [newKey, index] = key.split(/\[(.*)\]/)
      newObj = obj[newKey][index]
    }
    if (rest.length) return findValueOfNestedObject(rest.join('.'), newObj)
    else return newObj
  }
  if (/\[.*\]/.test(keyString)) {
    const [key, index] = keyString.split(/\[(.*)\]/)
    return obj[key][index]
  }
  return obj[keyString]
}

export function couponConditionJudgment(coupon, useStore) {
  const { coupon_condition } = coupon
  const invalidCondition = coupon_condition.map(({ property, operator, value, msg }) => {
    const [store, ...key] = property.split('.')
    const targetColumnValue = findValueOfNestedObject(key.join('.'), useStore.get(store))
    // console.log(coupon.coupon_name, targetColumnValue, property, operator);
    switch (operator) {
      case '==': return targetColumnValue === value ? null : msg
      case '!=': return targetColumnValue !== value ? null : msg
      case '>': return targetColumnValue > value ? null : msg
      case '<': return targetColumnValue < value ? null : msg
      case '>=': return targetColumnValue >= value ? null : msg
      case '<=': return targetColumnValue <= value ? null : msg
      case 'in': return targetColumnValue.includes(value) ? null : msg
      case 'daysAgo':
        return (Math.round(Date.now()/1000, 0)-targetColumnValue) > Number(value)*86400  ? null : msg
      case 'monthAgo': 
        return (Math.round(Date.now()/1000, 0)-targetColumnValue) > 2592000  ? null : msg
      case 'exist': return targetColumnValue ? null : msg
      case 'haveLength': return targetColumnValue && targetColumnValue.length ? null : msg
      case 'notHaveLength': return targetColumnValue && targetColumnValue.length===0 ? null : msg
      case 'includes': return targetColumnValue.find(e => {
        if (typeof value === 'object') return Object.keys(value).every(key => {
          //如果value是陣列就檢視 value array 是否包含指定欄位值
          if (Array.isArray(value[key])) return value[key].includes(e[key])
          //如果value是字串或數字就檢視指定欄位 array 是否包含 value
          else if (typeof value === 'string' || 'number') return e[key].includes(value[key]) 
          else return e[key] === value[key]
        })
        else return e === value
      }) ? null : msg
      case 'notIncludes': return targetColumnValue.find(e => {
        if (typeof value === 'object') return Object.keys(value).every(key => e[key] === value[key])
        else return e === value
      }) ? msg : null
      case 'notOnlyIncludes': return targetColumnValue.find(e => {
        if (typeof value === 'object') return Object.keys(value).every(key => e[key] === value[key])
        else return e === value
      }) && targetColumnValue.length === 1 ? msg : null
    }
  }).filter(e => e)
  console.log(invalidCondition);
  return invalidCondition.length ? invalidCondition : null
}
